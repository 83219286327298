import {useIntl} from 'react-intl';
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub';
import {AsideMenuItem} from './AsideMenuItem';

export function AsideMenuMain() {
  const intl = useIntl();

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Library</span>
        </div>
      </div>

      {/* questions */}
      <AsideMenuItemWithSub
        to='/question-bank'
        title='Question Bank'
        icon='/media/icons/duotune/medicine/med005.svg'
      >
        <AsideMenuItem to='/question-bank/chapters' title='Chapters' hasBullet={true} />
        <AsideMenuItem to='/question-bank/questions' title='Questions' hasBullet={true} />
        <AsideMenuItem to='/question-bank/challenges' title='Challenges' hasBullet={true} />
        <AsideMenuItem
          to='/question-bank/challenge-results'
          title='Challenge Results'
          hasBullet={true}
        />
      </AsideMenuItemWithSub>

      {/* media lessons */}
      <AsideMenuItemWithSub
        to='/media-lessons'
        title='Media Lessons'
        icon='/media/icons/duotune/general/gen006.svg'
      >
        <AsideMenuItem
          to='/media-lessons/lesson-categories'
          title='Lesson Categories'
          hasBullet={true}
        />
        <AsideMenuItem to='/media-lessons/lessons' title='Lessons' hasBullet={true} />
        <AsideMenuItem to='/media-lessons/mini-quizes' title='Mini Quizes' hasBullet={true} />
      </AsideMenuItemWithSub>

      {/* contents */}
      <AsideMenuItemWithSub
        to='/contents'
        title='Contents'
        icon='/media/icons/duotune/coding/cod002.svg'
      >
        <AsideMenuItem to='/contents/lexiques' title='Lexiques' hasBullet={true} />
        <AsideMenuItem to='/contents/fiches' title='Fiches' hasBullet={true} />
      </AsideMenuItemWithSub>

      {/* mindmap */}
      <AsideMenuItem
        to='/mindmaps'
        icon='/media/icons/duotune/maps/map009.svg'
        title='Mindmaps'
        fontIcon='bi-layers'
      />

      {/* market */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Market</span>
        </div>
      </div>
      {/* avatars, special objects, accessories */}
      <AsideMenuItem
        to='/objects'
        icon='/media/icons/duotune/ecommerce/ecm007.svg'
        title='Objects'
        fontIcon='bi-layers'
      />
      {/* subscriptions */}
      <AsideMenuItemWithSub
        to='/shop'
        title='Shop'
        icon='/media/icons/duotune/ecommerce/ecm011.svg'
      >
        <AsideMenuItem to='/shop/subjects' title='Subjects' hasBullet={true} />
        <AsideMenuItem to='/shop/subscriptions' title='Subscription' hasBullet={true} />
      </AsideMenuItemWithSub>

      {/* DOCS */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Docs</span>
        </div>
      </div>

      <AsideMenuItem
        to='/about-us'
        icon='/media/icons/duotune/general/gen044.svg'
        title='ABOUT'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/faqs'
        icon='/media/icons/duotune/general/gen046.svg'
        title='FAQs'
        fontIcon='bi-layers'
      />

      {/* management */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Management</span>
        </div>
      </div>

      <AsideMenuItem
        to='/admins'
        icon='/media/icons/duotune/general/gen049.svg'
        title='Admins'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/users'
        icon='/media/icons/duotune/communication/com014.svg'
        title='Users'
        fontIcon='bi-layers'
      />
       <AsideMenuItem
        to='/reviews'
        icon='/media/icons/duotune/communication/com010.svg'
        title='Reviews'
        fontIcon='bi-layers'
      />
       <AsideMenuItem
        to='/question-report'
        icon='/media/icons/duotune/communication/com007.svg'
        title='QuestionReport'
        fontIcon='bi-layers'
      />
    </>
  );
}
